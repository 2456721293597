import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import 'highlight.js/styles/monokai-sublime.css'

Vue.config.productionTip = false
Vue.use(ElementUI, { size: 'small' });

// console.log('VUE_APP_API_BASE_URL:', process.env.VUE_APP_API_BASE_URL);
// console.log('VUE_APP_API_KEY:', process.env.VUE_APP_API_KEY);
// console.log('VUE_APP_API_BASE_URL_PROMPT:', process.env.VUE_APP_API_BASE_URL_PROMPT);
// console.log('VUE_APP_API_KEY_PROMPT:', process.env.VUE_APP_API_KEY_PROMPT);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
